<template>
  <div class="mb-8 mt-8 w-full">
    <OneHeading :level="6" align="left" :content="Langs.get('giftCard-delivery')" />
    <!-- dispatch options -->
    <OneFormRadio
      class="mt-4"
      v-model="variantUserOptions.dispatch"
      :options="dispatchOptions"
      layout="grid"
      kind="outlined"
      label=""
      @update:modelValue="onToggleDispatch"
    />

    <div class="mt-2 text-left text-sm">
      <p v-if="variantUserOptions.dispatch == 'dispatchToCustomer'">
        {{ Langs.get("giftCard-dispatchToCustomer-info") }}
      </p>
      <p v-if="variantUserOptions.dispatch == 'dispatchToRecipient'">
        {{ Langs.get("giftCard-dispatchToRecipient-info") }}
      </p>
    </div>

    <!-- Gift card image -->
    <div class="py-8">
      <OneHeading :level="6" align="left" :content="Langs.get('giftCard-design')" />
      <div
        class="images mt-4 grid gap-2"
        :class="[
          {'none-selected': !someImageSelected},
          {'grid-cols-3': giftCardImagesCount < 4},
          {'grid-cols-4': giftCardImagesCount >= 4},
        ]"
      >
        <div
          style="aspect-ratio: 1"
          class="image columns overflow-hidden rounded-lg p-1 transition-all duration-500 ease-in-out"
          :class="{selected: isSelected(giftCardImage)}"
          v-for="giftCardImage in selectedVariant.config.giftCardImages"
          :key="giftCardImage.imageId"
          @click="onClickImage(giftCardImage)"
        >
          <div class="image-inner aspect-square w-full overflow-hidden rounded-md">
            <OneImage
              :src="imageUrl(giftCardImage)"
              class="h-full children:h-full md:h-screen"
              maxHeight="none"
              minHeight="none"
              fit="cover"
            />
          </div>
        </div>
      </div>

      <!-- language -->
      <div v-if="shopLangsAvailable && shopLangsAvailable.length > 1">
        <OneHeading
          class="mb-4 mt-8"
          :level="6"
          align="left"
          :content="Langs.get('giftCard-language')"
        />

        <OneFormRadio
          class="mt-4"
          v-model="variantUserOptions.language"
          :options="languageOptions"
          layout="grid"
          kind="outlined"
          label=""
        />
      </div>
    </div>

    <div class="mb-16" v-if="variantUserOptions.dispatch == 'dispatchToRecipient'">
      <OneHeading class="mb-4" :level="6" align="left" :content="Langs.get('giftCard-recipient')" />

      <div class="flex flex-col gap-2">
        <!-- recipient name -->
        <PfmInput
          v-model="variantUserOptions.recipient.name"
          name="recipientName"
          :label="Langs.get('giftCard-recipientName')"
          :placeholder="Langs.get('giftCard-recipientName-placeholder')"
          kind="input"
          inputType="name"
          :required="recipientFieldsRequired"
          @update:blur="onBlurRecipientName"
        />

        <!-- recipient email -->
        <PfmInput
          v-model="variantUserOptions.recipient.email"
          name="recipientEmail"
          :label="Langs.get('giftCard-recipientEmail')"
          :placeholder="Langs.get('giftCard-recipientEmail-placeholder')"
          kind="input"
          inputType="email"
          :required="recipientFieldsRequired"
          @update:blur="onBlurRecipientEmail"
        />
      </div>

      <OneHeading
        class="mb-4 mt-8"
        :level="6"
        align="left"
        :content="Langs.get('giftCard-sender')"
      />

      <div class="flex flex-col gap-2">
        <!-- sender name -->
        <PfmInput
          v-model="variantUserOptions.sender.name"
          name="senderName"
          :label="Langs.get('giftCard-senderName')"
          :placeholder="Langs.get('giftCard-senderName-placeholder')"
          kind="input"
          inputType="name"
          :required="senderFieldsRequired"
          @update:blur="onBlurSenderName"
        />

        <!-- sender email -->
        <PfmInput
          v-model="variantUserOptions.sender.email"
          name="senderEmail"
          :label="Langs.get('giftCard-senderEmail')"
          :placeholder="Langs.get('giftCard-senderEmail-placeholder')"
          kind="input"
          inputType="email"
          :required="senderFieldsRequired"
          @update:blur="onBlurSenderEmail"
        />
      </div>
    </div>

    <!-- message -->
    <OneHeading
      class="mb-4 mt-0"
      :level="6"
      align="left"
      :content="Langs.get('giftCard-message')"
    />

    <PfmInput
      ref="inputMessage"
      v-model="variantUserOptions.message"
      name="message"
      :label="Langs.get('giftCard-message')"
      :placeholder="Langs.get('giftCard-message-placeholder')"
      kind="input"
      :required="false"
      :allowEmoji="false"
      @update:blur="onBlurPersonalMessage"
    />

    <!-- download preview button is separately in GiftCardDownloadPreview.vue -->
  </div>
</template>

<script>
import Langs from "@/langs"
import {useShopStore} from "@/stores/shop"
import {useShopCartStore} from "@/stores/shopCart"
import {useSiteStore} from "@/stores/site"
import {useValidatorStore} from "@/stores/validator"
import {storeToRefs} from "pinia"
import {event} from "@/sink"

export default {
  name: "GiftCard",
  props: {
    selectedVariant: {
      type: Object,
    },
    variantUserOptions: {
      type: Object,
    },
    voluntaryPrice: {
      type: Number,
    },
  },
  emits: ["formValidityChange", "updated"],
  setup() {
    // stores
    const shopStore = useShopStore()
    const shopCartStore = useShopCartStore()
    const siteStore = useSiteStore()
    const validatorStore = useValidatorStore()

    // states
    const {shop} = storeToRefs(shopStore)
    const {siteVar, id: siteId, sitePlatform} = storeToRefs(siteStore)
    const {frontend} = storeToRefs(shopCartStore)

    // getters
    const {id: shopId} = storeToRefs(shopStore)
    const {siteLangCurrent} = storeToRefs(siteStore)
    const {invalidFields: validatorInvalidFields} = storeToRefs(validatorStore)

    // actions
    const {removeField: validatorRemoveField} = validatorStore

    return {
      siteVar,
      siteId,
      sitePlatform,
      shop,
      shopId,
      frontend,
      siteLangCurrent,
      validatorRemoveField,
      validatorInvalidFields,
    }
  },
  data: () => ({
    Langs,
    downloadTestGiftCardInProgress: false,
    isMessageValid: true, // message is optional, so it's initially valid
  }),
  watch: {
    formValid: {
      handler(newValue, oldValue) {
        if (newValue != oldValue) this.$emit("formValidityChange", newValue)
      },
    },
    "variantUserOptions.message": {
      handler() {
        this.isMessageValid = this.$refs.inputMessage.isValid
      },
    },
  },
  computed: {
    formValid() {
      let valid = true
      if (!this.variantUserOptions.dispatch) valid = false
      if (!this.variantUserOptions.giftCardImage.id) valid = false
      if (!this.variantUserOptions.language) valid = false
      if (!this.isMessageValid) valid = false

      if (this.variantUserOptions.dispatch == "dispatchToRecipient") {
        if (
          this.validatorInvalidFields.findIndex((f) => {
            return ["senderName", "senderEmail", "recipientName", "recipientEmail"].includes(f.name)
          }) !== -1
        )
          valid = false
      }
      return valid
    },
    shopLangsAvailable() {
      return this.shop.config.language.available
    },
    shopLangDefault() {
      return this.shop.config.language.default
    },
    languageOptions() {
      return this.shopLangsAvailable.map((lang) => ({
        value: lang,
        name: Langs.get(`language-in-${lang}`),
      }))
    },
    giftCardImagesCount() {
      if (!this.selectedVariant || !this.selectedVariant.config) return
      return this.selectedVariant.config.giftCardImages.length
    },
    recipientFieldsRequired() {
      return this.variantUserOptions.dispatch == "dispatchToRecipient"
    },
    senderFieldsRequired() {
      return this.variantUserOptions.dispatch == "dispatchToRecipient"
    },
    dispatchOptions() {
      let o = []
      if (this.selectedVariant.config.allowDispatchToCustomer) {
        o.push({
          name: Langs.get("giftCard-dispatchToCustomer"), //Vytisknu a predam",
          value: "dispatchToCustomer",
        })
      }
      if (this.selectedVariant.config.allowDispatchToRecipient) {
        o.push({
          name: Langs.get("giftCard-dispatchToRecipient"), //Ihned odeslat e-mailem prijemci",
          value: "dispatchToRecipient",
        })
      }
      return o
    },
    someImageSelected() {
      return this.variantUserOptions.giftCardImage.id
    },
  },
  created() {
    // // if site only has one language, set it
    // if (this.shopLangsAvailable && this.shopLangsAvailable.length === 1) {
    //   this.variantUserOptions.language = this.shopLangDefault
    // }
    this.init()
  },
  methods: {
    init() {
      this.preselectDispatchToCustomer()
      this.preselectFirstImage()
      this.preselectActiveLanguage()
    },
    preselectDispatchToCustomer() {
      if (this.selectedVariant.config.allowDispatchToCustomer) {
        this.variantUserOptions.dispatch = "dispatchToCustomer"
      } else if (this.selectedVariant.config.allowDispatchToRecipient) {
        this.variantUserOptions.dispatch = "dispatchToRecipient"
      }
    },
    preselectFirstImage() {
      if (this.selectedVariant.config.giftCardImages.length > 0) {
        this.selectImage(this.selectedVariant.config.giftCardImages[0].imageId)
      }
    },
    preselectActiveLanguage() {
      if (this.siteLangCurrent) {
        this.variantUserOptions.language = this.siteLangCurrent
      }
    },
    onToggleDispatch(v) {
      event("ProductGiftCardDispatchToSelect", {
        itemId: this.selectedVariant.id,
        to: v,
      })
      if (v == "dispatchToCustomer") this.resetValidatorFields()
    },
    resetValidatorFields() {
      this.validatorRemoveField({name: "senderName"})
      this.validatorRemoveField({name: "senderEmail"})
      this.validatorRemoveField({name: "recipientName"})
      this.validatorRemoveField({name: "recipientEmail"})
    },
    imageUrl(image) {
      let shopId = this.siteVar.shopId
      let imageId = image.imageId
      let imageConfig = "w_320,q_80,x_3"
      let imageUrl = `${
        import.meta.env.VITE_URL_STATIC
      }/shops/${shopId}/images/${imageId}$${imageConfig}`
      // console.log('imageUrl():', this.selectedVariant.config.images, mainImage, variantImage, imageShown, imageId)
      return imageUrl
    },
    selectImage(imageId) {
      console.log("selectImage", imageId)
      this.variantUserOptions.giftCardImage.id = imageId

      this.$emit("updated")
    },
    isSelected(image) {
      return this.variantUserOptions.giftCardImage.id == image.imageId
    },
    onClickImage(image) {
      this.selectImage(image.imageId)
      event("ProductGiftCardDesignSelect", {
        itemId: this.selectedVariant.id,
        option: image.imageId,
      })
    },
    onBlurPersonalMessage(v) {
      event("ProductGiftCardMessageFill", {
        itemId: this.selectedVariant.id,
      })
    },
    onBlurSenderName(v) {
      event("ProductGiftCardSenderNameFill", {
        itemId: this.selectedVariant.id,
      })
    },
    onBlurSenderEmail(v) {
      event("ProductGiftCardSenderEmailFill", {
        itemId: this.selectedVariant.id,
      })
    },
    onBlurRecipientName(v) {
      event("ProductGiftCardRecipientNameFill", {
        itemId: this.selectedVariant.id,
      })
    },
    onBlurRecipientEmail(v) {
      event("ProductGiftCardRecipientEmailFill", {
        itemId: this.selectedVariant.id,
      })
    },
  },
}
</script>

<style lang="less" scoped>
@import "../../../../variables.less";

.images {
  &.none-selected {
    .image {
      .image-inner {
        opacity: 1;
      }
    }
  }
  .image {
    cursor: pointer;

    box-shadow: 0 0 0 1px theme("colors.neutral.400");

    &:hover {
      box-shadow: 0 0 0 2px theme("colors.neutral.400");
      @apply border-neutral-700;
    }

    &.selected {
      box-shadow: 0 0 0 2px theme("colors.secondary.dark");

      .image-inner {
        opacity: 1;
      }
    }

    .image-inner {
      opacity: 0.5;
    }
  }
}
</style>

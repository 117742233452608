export default {
  text: {
    "auth-signIn-magicLinkSentTo": "magic link sent to",
    "auth-signIn-magicLinkWrongEmail": "wrong email?",
    "auth-signIn": "sign in",
    "auth-signOut": "sign out",

    "account-header": "account",

    "account-videos-header": "videos",

    // countries
    "country-CZ": "Czech Republic",
    "country-SK": "Slovakia",
    "country-AT": "Austria",
    "country-DE": "Germany",

    // languages
    "language-cs": "czech",
    "language-en": "english",
    "language-de": "german",

    // languages (in)
    "language-in-cs": "czech",
    "language-in-en": "english",
    "language-in-de": "german",

    "buy-for": "buy for",
    buy: "buy",
    "preorder-for": "preorder for",
    preorder: "preorder",
    "fill-form-first": "finish form",
    "sold-out": "sold out",
    "cart-remove": "remove",
    "cart-price-free": "free",
    "cart-price-from": "from",
    "cart-orders-closed": "orders closed",
    "cart-slot-full": "slot is full",
    "cart-pick-less-items": "pick less items",
    "cart-continue-shopping": "continue shopping",
    "cart-order-in": "order in",
    "cart-calendar-mo": "mo",
    "cart-calendar-tu": "tu",
    "cart-calendar-we": "we",
    "cart-calendar-th": "th",
    "cart-calendar-fr": "fr",
    "cart-calendar-sa": "sa",
    "cart-calendar-su": "su",
    "cart-calendar-yday": "yday",
    "cart-calendar-today": "today",
    "cart-calendar-tmrw": "tmrw",
    "cart-form-promocode-invalid": "Invalid code",
    "cart-form-optional": "optional",
    "cart-form-mistyped-something": "mistyped smoething?",
    "cart-form-street-number-missing": "We'd need a street number",
    "cart-form-address-restricted": "Unfortunately we don't deliver here",
    "cart-form-phone-missing": "we'd need a phone",
    "cart-form-email-missing": "we'd need an e-mail",
    "cart-form-name-missing": "we'd need a name",
    "cart-form-surname-missing": "we need name and surname",
    "cart-form-street-missing": "we'd need a street",
    "cart-form-city-missing": "we'd need a city",
    "cart-form-city-invalid": "that's not how city looks like",
    "cart-form-postal-missing": "we'd need postal code",
    "cart-form-postal-invalid": "that's not how postal code looks like",
    "cart-form-emoji-not-allowed": "Emojis are not allowed",
    "cart-order-pay": "pay",
    "cart-order-order-for": "order for",
    "cart-order-failed-something-wrong": "something went wrong :(",
    "cart-order-check-invalid-fields": "check invalid fields",
    "cart-payment-cardonline-processedby":
      "Card is processed by <a href='%0' target='_blank'>%1</a>,<br>we don't see its details.",
    "cart-payment-cardonline-testmode": "test mode",
    "cart-payment-cardonline-testmode-use-card": "use card",
    "cart-payment-cardonline-testmode-card-not-charged": "Card will not be charged.",

    // if you change this, change the admin translation file
    "cart-main-title": "Cart",
    "cart-header-backToShop": "Continue shoppping",
    "cart-itemList-empty": "Nothing here. 😔 Pick something 🙃",
    "cart-promoCode-title": "Promo code",
    "cart-promoCode-insert": "Use promo code",
    "cart-promoCode-field-promoCode-label": "Do you have a promo code?",
    "cart-promoCode-field-promoCode-placeholder":
      "Magic key, that makes you save while spending. 😜",
    "cart-promoCode-field-promoCode-helpText": "ABRACADABRA",
    "cart-customerLogin-signInWithPasskey": "Sign in with Passkey",
    "cart-customerLogin-or": "or",
    "cart-customerLogin-signInWithEmail": "use e-mail",
    "cart-customerLogin-signIn": "sign in",
    "cart-customerLogin-signOut": "sign out",
    "cart-customerSignUp-registerPasskey": "create Passkey",
    "cart-customerSignUp-nextTimeUsePasskey": "Next time you can sign in using your Passkey.",
    "cart-customerSignUp-nextTimeUseMagicLink": "Next time you can sign in using your email.",
    "cart-customerSignUp-usePasswordInstead": "set password instead",
    "cart-delivery-title": "Delivery",
    "cart-delivery-branch-title": "Branch",
    "cart-delivery-undeliverableItemsCombination":
      "This product combination can't be delivered. Please order separately.",
    "cart-delivery-deliveryOptions": "Delivery options",
    "cart-customerInfo-title": "My info",
    "cart-customerInfo-actionButtons-addBillingInfo": "Enter billing info",
    "cart-customerInfo-fields-customerEmail-label": "E-mail",
    "cart-customerInfo-fields-customerEmail-placeholder": "john@smith.com",
    "cart-customerInfo-fields-customerEmail-helpText":
      "We need your e-mail to inform you about your order state.",
    "cart-customerInfo-fields-customerName-label": "Name and surname",
    "cart-customerInfo-fields-customerName-placeholder": "John Smith",
    "cart-customerInfo-fields-customerName-helpText": "",
    "cart-customerInfo-fields-customerPhone-label": "Phone",
    "cart-customerInfo-fields-customerPhone-placeholder": "123 456 789",
    "cart-customerInfo-fields-customerPhone-helpText":
      "We need your phone in order to contact you promply in case of an unexpected event.",
    "cart-billingInfo-title": "Invoicing",
    "cart-billingInfo-actionButtons-fillManually": "Fill manually",
    "cart-billingInfo-actionButtons-companyAddress": "Invoice company",
    "cart-billingInfo-fields-billingAddress-label": "Address",
    "cart-billingInfo-fields-billingAddress-placeholder": "Main st. 1, Prague",
    "cart-billingInfo-fields-billingAddress-helpText": "",
    "cart-billingInfo-fields-billingAddressStreet-label": "Street",
    "cart-billingInfo-fields-billingAddressStreet-placeholder": "Main st. 1, Prague",
    "cart-billingInfo-fields-billingAddressStreet-helpText": "",
    "cart-billingInfo-fields-billingAddressCity-label": "City",
    "cart-billingInfo-fields-billingAddressCity-placeholder": "Prague",
    "cart-billingInfo-fields-billingAddressCity-helpText": "",
    "cart-billingInfo-fields-billingAddressPostal-label": "Postal",
    "cart-billingInfo-fields-billingAddressPostal-placeholder": "110 00",
    "cart-billingInfo-fields-billingAddressPostal-helpText": "",
    "cart-billingInfo-fields-billingAddressCompanyICO-label": "Reg. no.",
    "cart-billingInfo-fields-billingAddressCompanyICO-placeholder": "123 45 678",
    "cart-billingInfo-fields-billingAddressCompanyICO-helpText": "",
    "cart-billingInfo-fields-billingAddressCompanyName-label": "Company",
    "cart-billingInfo-fields-billingAddressCompanyName-placeholder": "Company s.r.o.",
    "cart-billingInfo-fields-billingAddressCompanyName-helpText": "",
    "cart-billingInfo-fields-invoiceNote-label": "Invoice note",
    "cart-billingInfo-fields-invoiceNote-placeholder": "Gift for the boss",
    "cart-billingInfo-fields-invoiceNote-helpText": "",
    "cart-deliveryInfo-title": "Delivery",
    "cart-deliveryInfo-actionButtons-fillManually": "Fill manually",
    "cart-deliveryInfo-actionButtons-companyAddress": "Deliver to a business",
    "cart-deliveryInfo-actionButtons-otherBillingInfo": "Other billing info",
    "cart-deliveryInfo-actionButtons-addBillingInfo": "Add billing info",
    "cart-deliveryInfo-recipientSameAsCustomerOptions-true": "for me",
    "cart-deliveryInfo-recipientSameAsCustomerOptions-false": "as a gift",
    "cart-deliveryInfo-fields-recipientName-label": "Name and surname",
    "cart-deliveryInfo-fields-recipientName-placeholder": "John Smith",
    "cart-deliveryInfo-fields-recipientName-helpText": "",
    "cart-deliveryInfo-fields-recipientPhone-label": "Phone",
    "cart-deliveryInfo-fields-recipientPhone-placeholder": "123 456 789",
    "cart-deliveryInfo-fields-recipientPhone-helpText": "We need a phone for the delivery courier.",
    "cart-deliveryInfo-fields-deliveryAddress-label": "Adresa",
    "cart-deliveryInfo-fields-deliveryAddress-placeholder": "Main st. 1, Prague",
    "cart-deliveryInfo-fields-deliveryAddress-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressStreet-label": "Street",
    "cart-deliveryInfo-fields-deliveryAddressStreet-placeholder": "Main st. 1, Prague",
    "cart-deliveryInfo-fields-deliveryAddressStreet-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressCity-label": "City",
    "cart-deliveryInfo-fields-deliveryAddressCity-placeholder": "Prague",
    "cart-deliveryInfo-fields-deliveryAddressCity-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressPostal-label": "Postal",
    "cart-deliveryInfo-fields-deliveryAddressPostal-placeholder": "110 00",
    "cart-deliveryInfo-fields-deliveryAddressPostal-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressCompanyICO-label": "Reg. no.",
    "cart-deliveryInfo-fields-deliveryAddressCompanyICO-placeholder": "123 456 789",
    "cart-deliveryInfo-fields-deliveryAddressCompanyICO-helpText": "",
    "cart-deliveryInfo-fields-deliveryAddressCompanyName-label": "Company",
    "cart-deliveryInfo-fields-deliveryAddressCompanyName-placeholder": "Company s.r.o.",
    "cart-deliveryInfo-fields-deliveryAddressCompanyName-helpText": "",
    "cart-deliveryInfo-fields-deliveryNote-label": "Delivery note",
    "cart-deliveryInfo-fields-deliveryNote-placeholder": "",
    "cart-deliveryInfo-fields-deliveryNote-helpText": "",
    "cart-deliveryInfo-fields-recipientNote-label": "Recipient note",
    "cart-deliveryInfo-fields-recipientNote-placeholder": "",
    "cart-deliveryInfo-fields-recipientNote-helpText": "",
    "cart-deliveryInfo-fields-contactless-labelTrue": "Contactless delivery",
    "cart-deliverySlots-noMoreDaysAvailable": "no other days<br>planned yet 😔",
    "cart-deliverySlots-noMoreSlotsAvailable": "No more free<br>slots available 😔",
    "cart-note-title": "Note",
    "cart-note-insert": "Add note",
    "cart-note-fields-note-label": "Anything else on your mind?",
    "cart-note-fields-note-placeholder": "Load!",
    "cart-note-fields-note-helpText":
      "We will try to give you the moon! We're still people, though 😜",
    "cart-payment-title": "Payment",
    "cart-stripeWallet-otherMethod": "Other method",
    "cart-stripeWallet-total": "Total",
    "cart-orderButton-terms-pre": "By payment I agree to",
    "cart-orderButton-terms-link": "terms",
    "cart-confirmation-orderNumber-heading": "order",
    "cart-confirmation-email-heading": "confirmation",
    "cart-confirmation-merchantNote": "Looking forward to next time!",
    "cart-confirmation-continueShoppingBtn": "something else? 😜",
    "cart-confirmation-watchVideoNow": "watch video",
    "cart-confirmation-downloadNow": "download",

    // confirmation texts - these are configured in delivery methods
    "cart-confirmation-method-recipientSameAsCustomer-title": "Thank you",
    "cart-confirmation-method-recipientSameAsCustomer-paragraph": "Your order is being processed.",
    "cart-confirmation-method-recipientNotSameAsCustomer-title": "Thank you",
    "cart-confirmation-method-recipientNotSameAsCustomer-paragraph":
      "Your order is being processed and soon will be dispatched to the recipient.",

    "delivery-method-constraints-label": "Delivery constraints",
    "delivery-method-constraints-min-dispatch-time": "Dispatched after",
    "delivery-method-constraints-shipping-from": "Dispatched from",
    "delivery-method-constraints-shipping-to": "Dispatched till",

    main_title: "Administration",

    "menu-pages": "Pages",
    "menu-commerce": "Commerce",
    "menu-analytics": "Analytics",
    "menu-settings": "Settings",
    "menu-help": "Help",
    "menu-logout": "Logout",
    "menu-publisher": "Publisher",
    "menu-designer": "Designer",

    "sites-main-headline": "My Sites",

    "option-yes": "Yes",
    "option-no": "No",

    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],

    "login-header": "Login",
    "login-header-label": "Proceed to login here...",
    "login-user-name": "User name",
    "login-user-password": "Password",
    "login-user-email": "E-mail",
    "login-submit-button": "Sign in",
    "login-help": "Need help?",
    "login-use-pwd": "Use password",
    "login-use-pwdless": "Use e-mail link",
    "login-msg-welcome": "platforma.one",
    "login-error-wrong-password": "Wrong password",
    "login-error-too-many-requests": "Too many attempts, try later.",
    "login-validator-passwordlessemail-errormessage-notvalid":
      "Not close enough. Misspelled smoething?",
    "login-validator-passwordlessemail-errormessage-notfound": "We didn't find you. Are you real?",
    "login-validator-passwordlessemail-errormessage-empty": "This is the place to put your e-mail.",
    "login-validator-passwordemail-errormessage-bad": "This",
    "login-validator-passwordemail-errormessage-empty": "Come on, be more specific.",
    "login-validator-passwordemail-errormessage-notvalid": "That's not an email!",
    "login-validator-password-errormessage-bad": "or that is not correct.",
    "login-validator-password-errormessage-empty": "Come on, passwords are important!",
    "login-email-instructions-headline": "Check your email",
    "login-email-sent-instructions-pt1": "Login email is on its way to",
    "login-email-sent-instructions-pt2": "",
    "login-email-sent-instructions-pt3": "You may close this window.",
    "login-email-instructions-tryagain": "Try again",

    "guest-signup-email-instructions-headline": "Wohoo, only one step away from your site!",
    "guest-signup-email-sent-instructions-pt1":
      "Confirmation link to publish your site was sent to",
    "guest-signup-email-sent-instructions-pt2":
      "You may close this window, new one will be opened from the link you will receive.",
    "guest-signup-published-headline": "It's on!",
    "guest-signup-published-text": "Your site is running live at",
    "guest-signup-published-open-btn": "Check it out",

    "main-site-menu": "Main menu",

    "settings-header": "Settings",
    "site-config-title": "Site title",

    "pages-header": "Pages",
    "page-list": "Page list",
    "main-view-site": "View site",
    "page-selected-layout": "Layout",
    "page-selected-content": "Content",
    "page-selected-settings": "Settings",
    "page-selected-styles": "Styles",
    "page-setup-title": "Title",
    "page-setup-role": "Required role",
    "page-setup-description": "Desription",
    "page-setup-slugs": "Slugs",
    "page-setup-visible": "Visible",
    "page-button-delete": "Delete page",
    "page-add-hint": "Click a point to insert",
    "page-duplicate": "Duplicate",
    "page-duplicate-parent": "Parent page",
    "page-duplicate-new-title": "New page title",
    "page-duplicate-execute": "Make it so",
    "page-clone-fill_name": "Fill page name for language: ",
    "page-clone-parent_no_slug": "Parent page has no slug!",
    "page-clone-slag_for_lang": "Parent page has no slug for language: ",
    "page-clone-done": "Duplication completed.",
    "page-clone-error": "Duplication failed!",
    "page-block-styling": "Styling",
    "label-add-language-version": "Add language version",

    "commerce-header": "E-shop",
    "commerce-shop-name": "Shop name",
    "commerce-no-site": "Select the website first",
    "commerce-new-eshop": "Create new e-shop",
    "commerce-orders": "Orders",
    "commerce-inventory": "Inventory",
    "commerce-shop-items": "Shop items",
    "commerce-customers": "Customers",
    "commerce-subscriptions": "Subscriptions",
    "commerce-orders-setup": "Settings",
    "commerce-orders-flow-setup": "Order flow setup",
    "commerce-shop-create-model": "Create new product form specification",
    "commerce-shop-update-model": "Update product form specification",
    "commerce-products-header": "Products",
    "commerce-product-attr-name": "Field name",
    "commerce-product-model-name": "Product type name",
    "commerce-shop-create-product": "Create new product",
    "commerce-shop-update-product": "Update product details",
    "commerce-product-header": "Product",
    "commerce-fee-reports": "Fee reports",
    "commerce-no-products-available": "no products",
    "commerce-display-all": "show all",
    "commerce-go-to-cart": "to checkout",
    "product-created-info": "Product has been created.",
    "product-not-created-error": "A problem prevented product creation!",
    "product-saved-info": "Product has been updated.",
    "product-not-saved-error": "A problem prevented product alteration!",
    "product-question-delete": "Do you really want to remove the product (including variants)?",
    "product-image-main": "Main product",
    "product-image-variant": "Main variant",
    "product-fees": "Fees",
    "upload-file-in-process": "Upload in progress",
    "commerce-setup": "Commerce setup",
    "commerce-supported-curr": "Supported currencies",
    "commerce-default-curr": "Default currency",
    "commerce-supported-langs": "Supported languages",
    "commerce-default-lang": "Default language",
    "commerce-order-num-format": "Order number format",
    "commerce-order-num-format-hint": "...",
    "commerce-sell-units": "Possible sale units",
    "commerce-sell-units-hint": "...",
    "commerce-bank-connection": "Bank connection",
    "commerce-bank-name": "Bank name",
    "commerce-bank-code": "Bank code",
    "commerce-account-number": "Account number",
    "commerce-iban": "IBAN",
    "commerce-swift": "SWIFT",
    "commerce-company-address": "Company address",
    "commerce-setup-payments": "Payment setup",
    "commerce-payment-methods": "Payment methods",
    "commerce-payment-method-add": "Add another payment method",
    "commerce-tax-values": "Available tax values",
    "commerce-delivery-channels": "Supported delivery methods",
    "commerce-setup-deliveries": "Deliveries setup",
    "commerce-delivery-method-add": "Add another delivery method",
    "commerce-setup-delivery-planning-settings": "Delivery planning settings",
    "commerce-setup-delivery-planning": "Delivery planning",
    "commerce-delivery-next-day-order": "Last next-day order at:",
    "commerce-delivery-next-days": "Show next days:",
    "commerce-delivery-use-defaults": "Use defaults",
    "commerce-delivery-add-slot": "+ Add Slot",
    "commerce-delivery-additional-fields": "Additional cart form fields",
    "commerce-delivery-add-field": "+ Add field",
    "commerce-delivery-planning": "Delivery planning",
    "commerce-delivery-default-slots": "Slots template",
    "commerce-setup-delivery-planning-slots": "Active slots",
    "commerce-delivery-settings": "Settings",
    "commerce-flow-delete-error": "Not possible to delete state. It is used in: [%0]",
    "commerce-flow-cmd-set": "Set a value",
    "commerce-flow-cmd-exe": "Execute operation",
    "commerce-flow-operation-request-liftago": "Request Liftago",
    "commerce-terms-agreement":
      'By payment, I accept <a target="_blank" href="%0">terms & conditions</a>',
    "commerce-product-not-found": "Product not found",
    "commerce-product-video-vod-full-avilable-after-purchase":
      "Full video will become available immediately after purchase in your account section.",

    "commerce-courier-liftago-courierPosition": "Courier location",
    "commerce-courier-liftago-rideStatus-PROCESSING": "Looking for driver",
    "commerce-courier-liftago-rideStatus-ACCEPTED": "Driver selected",
    "commerce-courier-liftago-rideStatus-WAITING": "Driver awaiting pickup",
    "commerce-courier-liftago-rideStatus-IN_PROGRESS": "Items on board",
    "commerce-courier-liftago-rideStatus-COMPLETED": "Delivery finished",
    "commerce-courier-liftago-rideStatus-CANCELLED": "Delivery cancelled",
    "commerce-courier-liftago-rideStatus-REJECTED": "Couldn't find driver",
    "commerce-courier-liftago-stopState-IN_QUEUE": "In delivery",
    "commerce-courier-liftago-stopState-DELIVERED": "Delivered",
    "commerce-courier-liftago-stopState-UNDELIVERED": "Not delivered",

    "stripe-error-card-expired": "Card expired. Please use another card.",
    "stripe-decline-card-expired": "Card expired. Please use another card.",
    "stripe-decline-auth-required": "Payment needs authorization. Please try again.",
    "stripe-decline-try-again": "Please try again or contact your bank.",
    "stripe-decline-contact-bank": "Please contact your bank.",
    "stripe-decline-duplicate": "Duplicate payment.",
    "stripe-decline-incorrect-number": "Incorrect card number",
    "stripe-decline-incorrect-cvc": "Incorrect CVC",
    "stripe-decline-incorrect-expiry": "Incorrect expiry",
    "stripe-decline-insufficient-funds": "Please check your account balance.",
    "stripe-decline-test-card": "Do not use test card.",
    "stripe-decline-test-mode-live-card": "Live card used in test mode",

    "address-street": "Street",
    "address-number": "Building Number",
    "address-area": "Area",
    "address-city": "City",
    "address-zip": "ZIP code",
    "address-region": "Region",
    "address-country": "Country",
    "commerce-invoice-disclaimer": "Invoice disclaimer",

    "product-field-title": "Title",
    "product-field-description": "Description",
    "product-tags": "Tags",
    "product-sku": "SKU",
    "product-options": "Options",
    "product-images": "Images",
    "product-quantity": "Stock & visibility",
    "product-shipping": "Shipping",
    "product-shipping-available-deliveries": "Available ways of delivery",
    "product-channels": "Channels",
    "system-settings": "System settings",
    "other-settings": "Other",
    "channel-online": "Online",
    "product-files": "Files",
    "add-a-new": "Add another:",
    "button-add-variant": "Add variant",
    "button-add-product": "Product",
    "button-add-option": "+ Add option",
    "button-add-attribute": "+ Add atrribute",
    "product-field-type": "Type",
    "product-field-name": "Name",
    "question-product-opt-delete": "Do you really want to delete this option?",
    "question-product-product-delete": "Do you really want to delete this product?",
    "question-product-product-variants-delete":
      "Do you really want to delete this product and its variants?",
    "product-enable-stock-track": "track stock",
    "product-min-order": "Minimal order amount:",
    "product-when-sold-out": "When sold out:",
    "product-on-sold-hide": "Hide",
    "product-on-sold-disable": "Restrict",
    "product-on-sold-preorder": "Preorder",
    "product-show-as": "Visibility",
    "product-show-as-hide": "Hidden",
    "product-show-as-unavailable": "Unavailable",
    "product-show-as-available": "Available",
    "product-show-as-auto": "By stock",
    "product-show-stock-counts": "Show stock counts",
    "product-pricing": "Pricing",
    "product-enable-reservation": "Allow reservation",
    "product-reservation-timeout": "Time to keep product in cart for order",
    "commerce-pricing-base": "Base",
    "commerce-pricing-tax": "Tax",
    "commerce-pricing-final": "Final",
    "commerce-pricing-purchased": "Purchased",
    "commerce-pricing-commission": "Commission",
    "commerce-pricing-profit": "Profit",
    "shipping-weight": "Weight [kg]",
    "shipping-dimensions": "Dimensions [cm]",
    "question-product-file-attr-delete": "Do you really want to delete this attribute?",
    "question-product-file-delete": "Do you really want to delete this file?",

    "preorder-starts-in": "preorder starts in",
    "preorder-ends-in": "preorder ends in",
    "preorder-ended": "preorder ended",
    "preorder-error-tooSoon":
      "Order contains a product for preorder that did not start yet. Remove it or order later.",
    "preorder-error-tooLate":
      "Order contains a product for preorder that already ended. Remove it and order again.",

    "signup-email": "What is your email?",
    "signup-site-domain": "What will be your site domain?",
    "signup-done": "... check your email inbox.",

    "products-prepared": "Ready -> Sent",

    "button-cancel": "Cancel",
    "button-confirm": "Confirm",
    "button-remove": "Remove",
    "button-save-draft": "Save draft",
    "button-publish": "Publish",
    "button-publish-all": "Publish all",
    "button-save": "Save",
    "button-add": "Add",
    "button-add-file": "Add file",
    "button-change-file": "Change file",
    "button-add-font": "Add font",
    "button-delete": "Delete",
    "button-all": "All",
    "button-signup": "Signup",

    "order-incomming-new": "New order!",
    // "order-incomming-new" : "New order! [%0]",
    "orders-customer": "Customer",
    "orders-products": "Products",
    "orders-shipping": "Shipping",
    "order-payment-not-paid": "Not paid yet",
    "order-payment-paid": "Paid at:",
    "order-customer-note": "Customer note",
    "order-customer-notify": "Notify customer",
    "order-note-add": "Add note",
    "order-pay-products": "Products",
    "order-pay-delivery": "Shipping",
    "order-pay-payment": "Payment",
    "order-pay-discount": "Discount",
    "order-pay-total": "Customer pays",
    "order-pay-method-card-online": "Card online",
    "order-pay-method-card-site": "Card in a shop",
    "order-pay-method-card-deliv": "Card at the delivery",
    "order-pay-method-cash-site": "Cash in a shop",
    "order-pay-method-cash-deliv": "Cash at the delivery",
    "order-pay-method-bank-wire": "bank wire",
    "order-pay-method-paypal": "Paypal",
    "order-shipping-method-branch": "Branch pickup",
    "order-shipping-method-messanger": "Kurýr",
    "order-shipping-method-ppl": "PPL",
    "order-shipping-method-ups": "UPS",
    "order-shipping-method-zasilk": "Zásilkovna",
    "order-shipping-method-cpost": "Czech post",
    "order-shipping-method-cpost-ob": "Czech post - business package",
    "order-shipping-method-custom": "Custom",
    "order-shipping-method-": "",

    oa_state_unpaid: "Unpaid",
    oa_state_paid: "Paid",
    oa_state_preparing: "Preparing products",
    oa_state_printing: "Printing labels",
    oa_state_packing: "Packing",
    oa_state_dispatching: "Dispatching",
    oa_state_sent: "Sent",
    oa_state_pickup: "Forpickup",
    oa_state_refunding: "Refunding",
    oa_state_cancelled: "Cancelled",
    oa_state_archived: "Archived",
    oa_go_paid: "Was paid",
    oa_go_prepare: "Prepare products",
    oa_go_print: "Print Label",
    oa_go_pack: "Pack goods",
    oa_go_cancel: "Cancel",
    oa_go_dispatch: "Dispatch",
    oa_go_send: "Send",
    oa_go_pickup: "Ready to pickup",
    oa_go_refund: "Refund",
    oa_go_archive: "Archive",
    oa_go_open: "Open",

    "billing-address": "Billing address",
    "delivery-address": "Delivery details",

    "block-config-name": "Block name",
    "block-config-tag": "Tag",
    "block-config-shared": "Shared",
    "block-config-region": "Region",
    "block-config-viewports": "Viewports",

    "forms-field-id": "ID",
    "forms-field-type": "Field type",
    "target-window-same": "Same window",
    "target-window-new": "New window",

    "form-font-name": "Font name",
    "form-font-weight": "Font weight",
    "form-font-url": "Font URL",
    "form-font-source-file": "Font file",

    "page-create-new": "New page",
    "page-create-new-abort": "Abort adding new page",
    "page-create-new-info": "Now select parental page of the new one",
    "page-new-name": "New page",
    "page-new-slug": "newpage",
    "page-new-created": "New page created",
    "page-new-not-created": "The page was not created.",
    "shared-blocks-label": "Shared",
    "private-blocks-label": "Private",

    "settings-css-header": "Styles",
    "settings-scripts-header": "Scripts",
    "settings-config-header": "Styles config",
    "settings-fonts-header": "Fonts",

    "template-editor": "Templates",
    "theme-label-fonts": "Fonts",
    "theme-label-scripts": "Script files",

    "field-listing-source": "Source",
    "field-listing-root-page": "Root page",

    "question-publish-all": "Do you relly want to publish all?",
    "message-info-site-published": "Site has been published.",
    "error-login-nonce-request":
      "Login failed. Please, repeat the process one more time, and in case it didn't work either, please do not hesitate to contact us.",
    "error-email-invlaid": "E-mail address is not valid!",
    "error-email-not-found": "E-mail address was not found!",
    "error-sitemName-already-used": "This site name is already used!",
    "error-email-already-used": "This E-mail address is already used!",
    "error-both-already-used": "Both values are already used!",
    "error-invlaid-values": "The form is not filled properly!",

    // block selector
    sections: "sections",
    "section-main": "main",
    "section-commerce": "commerce",
    "section-shared": "shared",
    "section-custom": "custom",
    "section-cancel": "zrušit",
    "section-main-hero": "hero",
    "section-main-space": "space",
    "section-main-separator": "separator",
    "section-main-header": "header",
    "section-main-cards": "cards",
    "section-main-button": "button",
    "section-main-footer": "footer",
    "section-main-menu": "menu",
    "section-main-gridList": "grid list",
    "section-main-logoCloud": "logo cloud",
    "section-main-popup": "pop-up",
    "section-main-headerText": "text with header",
    "section-main-gallery": "gallery",
    "section-main-instagram": "instagram",
    "section-main-text": "text",
    "section-main-image": "image",
    "section-main-imageText": "image with text",
    "section-main-video": "video",
    "section-main-team": "team",
    "section-commerce-productListing": "product listing",
    "section-commerce-productDetail": "product detail",
    "section-commerce-productPriceSlider": "product with voluntary price",
    "section-commerce-productImages": "product images",

    "product-variant": "variant",

    "priceVoluntary-voluntary": "Other amount",
    "priceVoluntary-price": "Set the value.",
    "giftCard-delivery": "Who do we send it to?",
    "giftCard-dispatchToCustomer": "me",
    "giftCard-dispatchToCustomer-info":
      "We will send an A4 PDF to e-mail entered in cart immediately after payment.",
    "giftCard-dispatchToRecipient": "someone else",
    "giftCard-dispatchToRecipient-info":
      "We will send e-mail voucher to the recipient immediately after payment.",
    "giftCard-design": "Choose a design.",
    "giftCard-message": "Personalize with a message.",
    "giftCard-message-placeholder": "Happy birthday!",
    "giftCard-download-preview": "Download preview",
    "giftCard-recipient": "Recipient",
    "giftCard-recipientName": "Recipient Name",
    "giftCard-recipientName-placeholder": "Peter",
    "giftCard-recipientEmail": "Recipient E-mail",
    "giftCard-recipientEmail-placeholder": "peter@gifted.com",
    "giftCard-sender": "Sender",
    "giftCard-senderName": "Sender Name",
    "giftCard-senderName-placeholder": "Anna",
    "giftCard-senderEmail": "Sender E-mail",
    "giftCard-senderEmail-placeholder": "anna@good.com",
    "giftCard-deliverTo": "Deliver to",
    "giftCard-language": "Language",
    "digitalProduct-download-sample": "Download free sample",

    "booking-guests": "Guests",
    "booking-date-slot": "Date & time",
    "booking-no-slots-available": "No slots available",
    "booking-date": "Date",
    "booking-slot": "Time",
    "booking-name": "Name",
    "booking-name-placeholder": "Peter Smith",
    "booking-email": "E-mail",
    "booking-email-placeholder": "peter@smith.com",
    "booking-phone": "Phone",
    "booking-phone-placeholder": "123 456 789",
    "booking-note": "Note",
    "booking-note-placeholder": "",
    "booking-preferred-language": "Preferred language",
    "booking-all-guests-are-adult": "All guests are 18+",

    "page-terms-heading": "Terms & Conditions",
  },
}
